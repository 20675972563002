import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/table"
import { Input } from "../ui/input"
import { Button } from "../ui/button"
import { Badge } from "../ui/badge"
import { Search, Plus, BlocksIcon, Check, Edit, Trash } from "lucide-react"
import UserForm from './UserForm';
import ConfirmDialog from '../common/ConfirmDialog';
import { useAuth } from '../../context/AuthContext';
import api from '../../services/api';

const UserManagement = () => {
    const { user: currentUser } = useAuth();
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, [page, rowsPerPage, search]);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await api.get('/users/', {
                params: {
                    page: page + 1,
                    page_size: rowsPerPage,
                    search,
                },
            });
            setUsers(response.data);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async (userData) => {
        try {
            if (selectedUser) {
                await api.put(`/users/${selectedUser.id}/`, userData);
            } else {
                await api.post('/users/', userData);
            }
            setOpenForm(false);
            fetchUsers();
        } catch (error) {
            console.error('Error saving user:', error);
        }
    };

    const handleDelete = async () => {
        try {
            await api.delete(`/users/${selectedUser.id}/`);
            setOpenDelete(false);
            setSelectedUser(null);
            fetchUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleToggleStatus = async (userId, currentStatus) => {
        try {
            await api.patch(`/users/${userId}/`, {
                is_active: !currentStatus,
            });
            fetchUsers();
        } catch (error) {
            console.error('Error toggling user status:', error);
        }
    };

    return (
        <div className="container py-8 mx-auto max-w-7xl">
            <div className="space-y-6">
                <div className="flex justify-between items-center">
                    <h1 className="text-3xl font-bold">User Management</h1>
                    <Button
                        onClick={() => {
                            setSelectedUser(null);
                            setOpenForm(true);
                        }}
                        className="gap-2"
                    >
                        <Plus className="h-4 w-4" />
                        Add User
                    </Button>
                </div>

                <div className="rounded-md border">
                    <div className="p-4">
                        <div className="flex items-center gap-2 max-w-sm">
                            <Search className="h-4 w-4 text-muted-foreground" />
                            <Input
                                placeholder="Search users..."
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                className="max-w-sm"
                            />
                        </div>
                    </div>

                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Username</TableHead>
                                <TableHead>Email</TableHead>
                                <TableHead>Role</TableHead>
                                <TableHead>Locations</TableHead>
                                <TableHead>Status</TableHead>
                                <TableHead className="text-right">Actions</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {users.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>
                                        <Badge
                                            variant={
                                                user.user_type === 'superadmin'
                                                    ? 'destructive'
                                                    : user.user_type === 'location_leader'
                                                        ? 'default'
                                                        : 'secondary'
                                            }
                                        >
                                            {user.user_type}
                                        </Badge>
                                    </TableCell>
                                    <TableCell>
                                        <div className="flex flex-wrap gap-1">
                                            {user.locations.map((location) => (
                                                <Badge
                                                    key={location.id}
                                                    variant="outline"
                                                >
                                                    {location.name}
                                                </Badge>
                                            ))}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <Badge
                                            variant={user.is_active ? 'success' : 'secondary'}
                                        >
                                            {user.is_active ? 'Active' : 'Inactive'}
                                        </Badge>
                                    </TableCell>
                                    <TableCell>
                                        <div className="flex justify-end gap-2">
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={() => handleToggleStatus(user.id, user.is_active)}
                                            >
                                                {user.is_active ? (
                                                    <BlocksIcon className="h-4 w-4" />
                                                ) : (
                                                    <Check className="h-4 w-4" />
                                                )}
                                            </Button>
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={() => {
                                                    setSelectedUser(user);
                                                    setOpenForm(true);
                                                }}
                                            >
                                                <Edit className="h-4 w-4" />
                                            </Button>
                                            {user.id !== currentUser.id && (
                                                <Button
                                                    variant="ghost"
                                                    size="icon"
                                                    onClick={() => {
                                                        setSelectedUser(user);
                                                        setOpenDelete(true);
                                                    }}
                                                >
                                                    <Trash className="h-4 w-4" />
                                                </Button>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <div className="flex items-center justify-end p-4">
                        <div className="flex items-center gap-2">
                            <select
                                className="rounded-md border px-2 py-1"
                                value={rowsPerPage}
                                onChange={(e) => {
                                    setRowsPerPage(parseInt(e.target.value, 10));
                                    setPage(0);
                                }}
                            >
                                <option value={10}>10 per page</option>
                                <option value={25}>25 per page</option>
                                <option value={50}>50 per page</option>
                            </select>
                            <Button
                                variant="outline"
                                onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                disabled={page === 0}
                            >
                                Previous
                            </Button>
                            <Button
                                variant="outline"
                                onClick={() => setPage(prev => prev + 1)}
                                disabled={users.length < rowsPerPage}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <UserForm
                open={openForm}
                user={selectedUser}
                onSave={handleSave}
                onClose={() => setOpenForm(false)}
            />

            <ConfirmDialog
                open={openDelete}
                title="Delete User"
                message="Are you sure you want to delete this user? This action cannot be undone."
                onConfirm={handleDelete}
                onCancel={() => setOpenDelete(false)}
            />
        </div>
    );
};

export default UserManagement;