import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "../ui/dialog"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../ui/select"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import { Button } from "../ui/button"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
} from "../ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../ui/popover"
import { X, Check } from "lucide-react"
import { cn } from "../../lib/utils"
import { Badge } from "../ui/badge"
import api from '../../services/api';

const UserForm = ({ open, user, onSave, onClose }) => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        user_type: 'volunteer',
        locations: [],
        phone: '',
    });
    const [locations, setLocations] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [openLocations, setOpenLocations] = useState(false);

    useEffect(() => {
        if (user) {
            setFormData({
                ...user,
                password: '',
            });
        } else {
            setFormData({
                username: '',
                email: '',
                password: '',
                user_type: 'volunteer',
                locations: [],
                phone: '',
            });
        }
    }, [user]);

    useEffect(() => {
        fetchLocations();
    }, []);

    const fetchLocations = async () => {
        try {
            const response = await api.get('/locations/');
            setLocations(response.data);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setErrors({});

        try {
            await onSave(formData);
            onClose();
        } catch (error) {
            if (error.response?.data) {
                setErrors(error.response.data);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {user ? 'Edit User' : 'Create New User'}
                    </DialogTitle>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="space-y-2">
                        <Label htmlFor="username">Username</Label>
                        <Input
                            id="username"
                            value={formData.username}
                            onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                            error={!!errors.username}
                            required
                        />
                        {errors.username && (
                            <p className="text-sm text-destructive">{errors.username}</p>
                        )}
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="email">Email</Label>
                        <Input
                            id="email"
                            type="email"
                            value={formData.email}
                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            error={!!errors.email}
                            required
                        />
                        {errors.email && (
                            <p className="text-sm text-destructive">{errors.email}</p>
                        )}
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="password">Password</Label>
                        <Input
                            id="password"
                            type="password"
                            value={formData.password}
                            onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                            error={!!errors.password}
                            required={!user}
                        />
                        {errors.password && (
                            <p className="text-sm text-destructive">{errors.password}</p>
                        )}
                        {user && (
                            <p className="text-sm text-muted-foreground">Leave blank to keep current password</p>
                        )}
                    </div>

                    <div className="space-y-2">
                        <Label>Role</Label>
                        <Select
                            value={formData.user_type}
                            onValueChange={(value) => setFormData({ ...formData, user_type: value })}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select role" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="volunteer">Volunteer</SelectItem>
                                <SelectItem value="location_leader">Location Leader</SelectItem>
                                <SelectItem value="superadmin">Super Admin</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>

                    <div className="space-y-2">
                        <Label>Locations</Label>
                        <Popover open={openLocations} onOpenChange={setOpenLocations}>
                            <PopoverTrigger asChild>
                                <Button
                                    variant="outline"
                                    role="combobox"
                                    className="w-full justify-between"
                                >
                                    {formData.locations.length > 0
                                        ? `${formData.locations.length} selected`
                                        : "Select locations"}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-[400px] p-0">
                                <Command>
                                    <CommandInput placeholder="Search locations..." />
                                    <CommandEmpty>No locations found.</CommandEmpty>
                                    <CommandGroup>
                                        {locations.map((location) => {
                                            const isSelected = formData.locations.includes(location.id);
                                            return (
                                                <CommandItem
                                                    key={location.id}
                                                    onSelect={() => {
                                                        setFormData(prev => ({
                                                            ...prev,
                                                            locations: isSelected
                                                                ? prev.locations.filter(id => id !== location.id)
                                                                : [...prev.locations, location.id]
                                                        }));
                                                    }}
                                                >
                                                    {location.name}
                                                    <Check
                                                        className={cn(
                                                            "ml-auto h-4 w-4",
                                                            isSelected ? "opacity-100" : "opacity-0"
                                                        )}
                                                    />
                                                </CommandItem>
                                            );
                                        })}
                                    </CommandGroup>
                                </Command>
                            </PopoverContent>
                        </Popover>
                        <div className="flex flex-wrap gap-2 mt-2">
                            {locations
                                .filter(location => formData.locations.includes(location.id))
                                .map(location => (
                                    <Badge
                                        key={location.id}
                                        variant="secondary"
                                        className="flex items-center gap-1"
                                    >
                                        {location.name}
                                        <X
                                            className="h-3 w-3 cursor-pointer"
                                            onClick={() => {
                                                setFormData(prev => ({
                                                    ...prev,
                                                    locations: prev.locations.filter(id => id !== location.id)
                                                }));
                                            }}
                                        />
                                    </Badge>
                                ))}
                        </div>
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="phone">Phone</Label>
                        <Input
                            id="phone"
                            value={formData.phone}
                            onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                            error={!!errors.phone}
                        />
                        {errors.phone && (
                            <p className="text-sm text-destructive">{errors.phone}</p>
                        )}
                    </div>

                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" disabled={loading}>
                            {user ? 'Save Changes' : 'Create User'}
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default UserForm;