import React, { useEffect, useState } from 'react';
import { Button } from "../ui/button"
import { Settings } from "lucide-react"
import KioskExitDialog from './KioskExitDialog';

const KioskMode = ({ children }) => {
    const [showExitDialog, setShowExitDialog] = useState(false);
    const [exitAttempts, setExitAttempts] = useState(0);

    useEffect(() => {
        // Enter fullscreen on component mount
        enterKioskMode();

        const preventDefaults = (e) => {
            e.preventDefault();
            return false;
        };

        const preventKeyboardShortcuts = (e) => {
            if (e.altKey || e.metaKey || e.key === 'F11' || e.key === 'Escape') {
                e.preventDefault();
                return false;
            }
        };

        // Add event listeners
        document.addEventListener('contextmenu', preventDefaults);
        document.addEventListener('keydown', preventKeyboardShortcuts);
        window.addEventListener('beforeunload', preventDefaults);

        // Periodic check for fullscreen
        const fullscreenCheck = setInterval(() => {
            if (!document.fullscreenElement) {
                enterKioskMode();
            }
        }, 1000);

        return () => {
            document.removeEventListener('contextmenu', preventDefaults);
            document.removeEventListener('keydown', preventKeyboardShortcuts);
            window.removeEventListener('beforeunload', preventDefaults);
            clearInterval(fullscreenCheck);
        };
    }, []);

    const enterKioskMode = async () => {
        try {
            const elem = document.documentElement;
            if (elem.requestFullscreen) {
                await elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                await elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                await elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                await elem.msRequestFullscreen();
            }
        } catch (error) {
            console.error('Error entering kiosk mode:', error);
        }
    };

    return (
        <div className="h-screen w-screen overflow-hidden relative select-none">
            {children}

            {/* Admin Settings Button (Hidden in corner) */}
            <Button
                variant="ghost"
                size="icon"
                className="absolute top-2 right-2 opacity-10 hover:opacity-10"
                onClick={() => setShowExitDialog(true)}
            >
                <Settings className="h-4 w-4" />
            </Button>

            <KioskExitDialog
                open={showExitDialog}
                onClose={() => setShowExitDialog(false)}
                onExit={() => {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    }
                    window.location.href = '/admin';
                }}
            />
        </div>
    );
};

export default KioskMode;