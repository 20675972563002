import React, { useState } from 'react';
import { Card, CardContent } from "../ui/card"
import { Button } from "../ui/button"
import { ArrowLeft, ArrowRight } from "lucide-react"
import EmotionSelector from './EmotionSelector';
import QuestionsStep from './QuestionStep';
import OptionalInfoStep from './OptionalInfoStep';
import ProgressDots from './ProgressDots';
import ThankYouScreen from './ThankYouScreen'
import api from '../../services/api';

const FeedbackForm = ({ categoryId }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        satisfaction_level: '',
        answers: [],
        comment: '',
        name: '',
        email: '',
        phone: '',
    });

    const steps = ['emotion', 'questions', 'optional'];

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleSubmit = async (skipOptional = false) => {
        try {
            setIsSubmitting(true);

            const submitData = {
                ...formData,
                page_category: categoryId,
            };

            if (skipOptional) {
                submitData.comment = '';
                submitData.name = '';
                submitData.email = '';
                submitData.phone = '';
            }

            await api.submitFeedback(submitData);
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error submitting feedback:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleReset = () => {
        setFormData({
            satisfaction_level: '',
            answers: [],
            comment: '',
            name: '',
            email: '',
            phone: '',
        });
        setActiveStep(0);
        setIsSubmitted(false);
    };

    if (isSubmitted) {
        return <ThankYouScreen onReset={handleReset} />;
    }

    return (
        <div className="container mx-auto max-w-2xl min-h-screen py-8 relative">
            {activeStep > 0 && (
                <Button
                    variant="ghost"
                    size="icon"
                    className="absolute top-4 left-4 opacity-60 hover:opacity-100"
                    onClick={handleBack}
                >
                    <ArrowLeft className="h-4 w-4" />
                </Button>
            )}

            <div className="flex flex-col items-center justify-center min-h-[calc(100vh-4rem)]">
                <Card className="w-full">
                    <CardContent className="p-6">
                        {activeStep === 0 && (
                            <div className="space-y-6">
                                <h2 className="text-2xl font-semibold text-center">
                                    How was your experience today?
                                </h2>
                                <EmotionSelector
                                    value={formData.satisfaction_level}
                                    onChange={(value) => {
                                        setFormData({ ...formData, satisfaction_level: value });
                                        setTimeout(handleNext, 300);
                                    }}
                                />
                            </div>
                        )}

                        {activeStep === 1 && (
                            <div className="space-y-6">
                                <h2 className="text-xl font-semibold text-center">
                                    Please answer a few questions
                                </h2>
                                <QuestionsStep
                                    categoryId={categoryId}
                                    answers={formData.answers}
                                    onChange={(answers) => {
                                        setFormData({ ...formData, answers });
                                    }}
                                    onComplete={handleNext}
                                />
                            </div>
                        )}

                        {activeStep === 2 && (
                            <OptionalInfoStep
                                formData={formData}
                                onChange={setFormData}
                                onSubmit={handleSubmit}
                                isSubmitting={isSubmitting}
                            />
                        )}

                        <ProgressDots
                            steps={steps.length}
                            activeStep={activeStep}
                            className="mt-6"
                        />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default FeedbackForm;