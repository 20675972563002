import axios from 'axios';

const API_URL = 'http://localhost:8000/api';

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add request interceptor
axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// Add response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const refreshToken = localStorage.getItem('refresh_token');

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/auth/token/refresh/`,
                    { refresh: refreshToken }
                );
                const { access } = response.data;
                localStorage.setItem('token', access);
                originalRequest.headers.Authorization = `Bearer ${access}`;
                return axiosInstance(originalRequest);
            } catch (refreshError) {
                localStorage.removeItem('token');
                localStorage.removeItem('refresh_token');
                window.location.href = '/login';
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

// Feedback API
export const submitFeedback = async (data) => {
    try {
        const response = await axiosInstance.post('/feedback/', data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Main API object with all endpoints
const api = {
    // Event Management
    getEvents: async (params) => {
        const response = await axiosInstance.get('/events/', { params });
        return response.data;
    },

    createEvent: async (eventData) => {
        const response = await axiosInstance.post('/events/', eventData);
        return response.data;
    },

    updateEvent: async (eventId, eventData) => {
        const response = await axiosInstance.put(`/events/${eventId}/`, eventData);
        return response.data;
    },

    // Analytics
    getAnalyticsOverview: async (params) => {
        const response = await axiosInstance.get('/analytics/overview/', { params });
        return response.data;
    },

    getEventAnalytics: async (params) => {
        const response = await axiosInstance.get('/analytics/events/', { params });
        return response.data;
    },

    getLocationAnalytics: async (params) => {
        const response = await axiosInstance.get('/analytics/locations/', { params });
        return response.data;
    },
    submitFeedback: async (data) => {
        // Simulate API call delay
        await new Promise(resolve => setTimeout(resolve, 1000));

        // Log the data that would be sent to the server
        console.log('Submitting feedback:', data);

        // For demo purposes, always return success
        return {
            success: true,
            message: 'Feedback submitted successfully'
        };
    },
};

export default api;