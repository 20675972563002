import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/table"
import { Button } from "../ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "../ui/dialog"
import { Edit, BarChart2, Trash } from "lucide-react"
import EventForm from './EventForm';
import EventAnalytics from './EventsAnalytics';
import ConfirmDialog from '../common/ConfirmDialog';
import api from '../../services/api';

const EventsManagement = () => {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openAnalytics, setOpenAnalytics] = useState(false);
    const [analyticsData, setAnalyticsData] = useState(null);

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        try {
            const response = await api.get('/events');
            setEvents(response.data);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    const handleSave = async (formData) => {
        try {
            if (selectedEvent) {
                await api.put(`/events/${selectedEvent.id}`, formData);
            } else {
                await api.post('/events', formData);
            }
            setOpenForm(false);
            fetchEvents();
        } catch (error) {
            console.error('Error saving event:', error);
        }
    };

    const handleDelete = async () => {
        try {
            await api.delete(`/events/${selectedEvent.id}`);
            setOpenDelete(false);
            fetchEvents();
        } catch (error) {
            console.error('Error deleting event:', error);
        }
    };

    const handleAnalytics = async (event) => {
        try {
            const response = await api.get(`/events/${event.id}/analytics`);
            setAnalyticsData(response.data);
            setOpenAnalytics(true);
        } catch (error) {
            console.error('Error fetching analytics:', error);
        }
    };

    return (
        <div className="container mx-auto max-w-6xl py-8">
            <div className="flex justify-between items-center mb-8">
                <h1 className="text-3xl font-bold">Events Management</h1>
                <Button
                    onClick={() => {
                        setSelectedEvent(null);
                        setOpenForm(true);
                    }}
                >
                    Create Event
                </Button>
            </div>

            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Name</TableHead>
                            <TableHead>Location</TableHead>
                            <TableHead>Start Date</TableHead>
                            <TableHead>End Date</TableHead>
                            <TableHead className="w-[100px]">Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {events.map((event) => (
                            <TableRow key={event.id}>
                                <TableCell>{event.name}</TableCell>
                                <TableCell>{event.location}</TableCell>
                                <TableCell>{format(new Date(event.start_date), 'PPp')}</TableCell>
                                <TableCell>{format(new Date(event.end_date), 'PPp')}</TableCell>
                                <TableCell>
                                    <div className="flex gap-2">
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => {
                                                setSelectedEvent(event);
                                                setOpenForm(true);
                                            }}
                                        >
                                            <Edit className="h-4 w-4" />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => handleAnalytics(event)}
                                        >
                                            <BarChart2 className="h-4 w-4" />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => {
                                                setSelectedEvent(event);
                                                setOpenDelete(true);
                                            }}
                                        >
                                            <Trash className="h-4 w-4" />
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>

            {/* Event Form Dialog */}
            <Dialog open={openForm} onOpenChange={() => setOpenForm(false)}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>
                            {selectedEvent ? 'Edit Event' : 'Create Event'}
                        </DialogTitle>
                    </DialogHeader>
                    <EventForm
                        event={selectedEvent}
                        onSave={handleSave}
                        onCancel={() => setOpenForm(false)}
                    />
                </DialogContent>
            </Dialog>

            {/* Analytics Dialog */}
            <Dialog open={openAnalytics} onOpenChange={() => setOpenAnalytics(false)}>
                <DialogContent className="max-w-4xl">
                    <DialogHeader>
                        <DialogTitle>Event Analytics</DialogTitle>
                    </DialogHeader>
                    <EventAnalytics eventData={analyticsData} />
                </DialogContent>
            </Dialog>

            {/* Confirm Delete Dialog */}
            <ConfirmDialog
                open={openDelete}
                title="Delete Event"
                message="Are you sure you want to delete this event? This action cannot be undone."
                onConfirm={handleDelete}
                onCancel={() => setOpenDelete(false)}
            />
        </div>
    );
};

export default EventsManagement;
