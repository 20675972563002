import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/table"
import { Button } from "../ui/button"
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "../ui/dialog"
import { Input } from "../ui/input"
import { Label } from "../ui/label"
import { Edit, Trash } from "lucide-react"
import api from '../../services/api';

const LocationManagement = () => {
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [openForm, setOpenForm] = useState(false);

    useEffect(() => {
        fetchLocations();
    }, []);

    const fetchLocations = async () => {
        try {
            const response = await api.get('/locations');
            setLocations(response.data);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (selectedLocation?.id) {
                await api.put(`/locations/${selectedLocation.id}`, selectedLocation);
            } else {
                await api.post('/locations', selectedLocation);
            }
            setOpenForm(false);
            fetchLocations();
        } catch (error) {
            console.error('Error saving location:', error);
        }
    };

    return (
        <div className="container mx-auto max-w-6xl py-8">
            <div className="flex justify-between items-center mb-8">
                <h1 className="text-3xl font-bold">Location Management</h1>
                <Button
                    onClick={() => {
                        setSelectedLocation(null);
                        setOpenForm(true);
                    }}
                >
                    Add Location
                </Button>
            </div>

            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Name</TableHead>
                            <TableHead>Address</TableHead>
                            <TableHead className="w-[100px]">Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {locations.map((location) => (
                            <TableRow key={location.id}>
                                <TableCell>{location.name}</TableCell>
                                <TableCell>{location.address}</TableCell>
                                <TableCell>
                                    <div className="flex gap-2">
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() => {
                                                setSelectedLocation(location);
                                                setOpenForm(true);
                                            }}
                                        >
                                            <Edit className="h-4 w-4" />
                                        </Button>
                                        <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={async () => {
                                                if (window.confirm('Are you sure you want to delete this location?')) {
                                                    await api.delete(`/locations/${location.id}`);
                                                    fetchLocations();
                                                }
                                            }}
                                        >
                                            <Trash className="h-4 w-4" />
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>

            <Dialog open={openForm} onOpenChange={() => setOpenForm(false)}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>
                            {selectedLocation?.id ? 'Edit Location' : 'Add Location'}
                        </DialogTitle>
                    </DialogHeader>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div className="space-y-2">
                            <Label htmlFor="name">Location Name</Label>
                            <Input
                                id="name"
                                value={selectedLocation?.name || ''}
                                onChange={(e) => setSelectedLocation(prev => ({
                                    ...prev,
                                    name: e.target.value
                                }))}
                                required
                            />
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="address">Address</Label>
                            <Input
                                id="address"
                                value={selectedLocation?.address || ''}
                                onChange={(e) => setSelectedLocation(prev => ({
                                    ...prev,
                                    address: e.target.value
                                }))}
                                required
                            />
                        </div>
                        <Button type="submit" className="w-full">
                            Save
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default LocationManagement;