
import React, { useState, useEffect } from 'react';
import { Button } from "../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import { Input } from "../ui/input";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../ui/select";
import { Switch } from "../ui/switch";
import { Label } from "../ui/label";
import { ExternalLink, QrCode } from 'lucide-react';
import QRCode from 'qrcode.react';
import api from '../../services/api';

const KioskManagement = () => {
    const [filters, setFilters] = useState({
        location: 'all',
        event: 'all',
        view: 'overall'
    });

    const [kioskConfig, setKioskConfig] = useState({
        location: '',
        event: '',
        idleTimeout: 300000, // 5 minutes
        showLocationInfo: true,
        allowSkipQuestions: false,
        customTheme: {
            primaryColor: '#1976d2',
            backgroundColor: '#ffffff',
            logoUrl: '',
        },
        adminPassword: '',
        autoResetTime: '23:00', // Daily reset time
        feedbackOptions: {
            requireComment: false,
            allowAnonymous: true,
            showProgress: true,
        },
    });


    useEffect(() => {
        fetchLocations();
        fetchEvents();
    }, []);

    const fetchLocations = async () => {
        try {
            const response = await api.getLocations();
            setLocations(response);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    const fetchEvents = async () => {
        try {
            const response = await api.getEvents();
            setEvents(response);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    const handleLaunchKiosk = () => {
        localStorage.setItem('kioskConfig', JSON.stringify(kioskConfig));
        const kioskUrl = new URL('/kiosk', window.location.origin);
        kioskUrl.searchParams.set('location', kioskConfig.location);
        if (kioskConfig.event) {
            kioskUrl.searchParams.set('event', kioskConfig.event);
        }
        window.open(kioskUrl.toString(), '_blank', 'fullscreen=yes,kiosk=yes');
    };

    const [showQRCode, setShowQRCode] = useState(false);
    const [locations, setLocations] = useState([]);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        fetchLocations();
        fetchEvents();
    }, []);



    const generateKioskUrl = (config) => {
        const url = new URL('/kiosk', window.location.origin);
        url.searchParams.set('location', config.location);
        if (config.event) {
            url.searchParams.set('event', config.event);
        }
        return url.toString();
    };

    return (
        <div className="container mx-auto p-8">
            <div className="flex justify-between items-center mb-8">
                <h1 className="text-3xl font-bold">Kiosk Management</h1>
                <div className="flex gap-4">
                    <Select
                        value={filters.location}
                        onValueChange={(value) => setFilters(prev => ({ ...prev, location: value }))}
                    >
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Select Location" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="all">All Locations</SelectItem>
                            {locations.map(location => (
                                <SelectItem key={location.id} value={location.id}>
                                    {location.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>

                    <Select
                        value={filters.event}
                        onValueChange={(value) => setFilters(prev => ({ ...prev, event: value }))}
                    >
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Select Event" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="all">All Events</SelectItem>
                            {events.map(event => (
                                <SelectItem key={event.id} value={event.id}>
                                    {event.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <div className="grid gap-6 md:grid-cols-2">
                <Card>
                    <CardHeader>
                        <CardTitle>Basic Configuration</CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <div className="space-y-2">
                            <Label htmlFor="adminPassword">Admin Password</Label>
                            <Input
                                id="adminPassword"
                                type="password"
                                value={kioskConfig.adminPassword}
                                onChange={(e) => setKioskConfig({
                                    ...kioskConfig,
                                    adminPassword: e.target.value
                                })}
                            />
                        </div>

                        <div className="space-y-2">
                            <Label htmlFor="resetTime">Daily Reset Time</Label>
                            <Input
                                id="resetTime"
                                type="time"
                                value={kioskConfig.autoResetTime}
                                onChange={(e) => setKioskConfig({
                                    ...kioskConfig,
                                    autoResetTime: e.target.value
                                })}
                            />
                        </div>
                    </CardContent>
                </Card>

                <Card>
                    <CardHeader>
                        <CardTitle>Display Settings</CardTitle>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <div className="flex items-center justify-between">
                            <Label htmlFor="showLocation">Show Location Information</Label>
                            <Switch
                                id="showLocation"
                                checked={kioskConfig.showLocationInfo}
                                onCheckedChange={(checked) => setKioskConfig({
                                    ...kioskConfig,
                                    showLocationInfo: checked
                                })}
                            />
                        </div>

                        <div className="flex items-center justify-between">
                            <Label htmlFor="skipQuestions">Allow Skipping Questions</Label>
                            <Switch
                                id="skipQuestions"
                                checked={kioskConfig.allowSkipQuestions}
                                onCheckedChange={(checked) => setKioskConfig({
                                    ...kioskConfig,
                                    allowSkipQuestions: checked
                                })}
                            />
                        </div>
                    </CardContent>
                </Card>
            </div>

            <Card className="mt-6">
                <CardContent className="flex justify-center gap-4 p-6">
                    <Button
                        onClick={handleLaunchKiosk}
                        className="gap-2"
                    >
                        <ExternalLink className="h-4 w-4" />
                        Launch Kiosk Mode
                    </Button>
                    <Button
                        variant="outline"
                        onClick={() => setShowQRCode(true)}
                        className="gap-2"
                    >
                        <QrCode className="h-4 w-4" />
                        Generate QR Code
                    </Button>
                </CardContent>
            </Card>
        </div>
    );
};

export default KioskManagement;