import React from 'react';
import { Input } from "../ui/input"
import { Textarea } from "../ui/textarea"
import { Button } from "../ui/button"
import { Label } from "../ui/label"
import { Separator } from '@radix-ui/react-select';
import { UserIcon, Mail, Phone, MessageSquare, ArrowRight } from "lucide-react"
import api from '../../services/api';

const OptionalInfoStep = ({ formData, onChange, onSubmit, isSubmitting }) => {
    const handleChange = (field, value) => {
        onChange({ ...formData, [field]: value });
    };
    const handleSkip = () => {
        onSubmit(true); // true indicates skipping optional fields
    };

    const handleSubmit = async (skipOptional = false) => {
        try {
            //setIsSubmitting(true);

            const submitData = {
                ...formData,
                //page_category: categoryId,
            };

            if (skipOptional) {
                submitData.comment = '';
                submitData.name = '';
                submitData.email = '';
                submitData.phone = '';
            }

            await api.submitFeedback(submitData);
            //setIsSubmitted(true);
        } catch (error) {
            console.error('Error submitting feedback:', error);
        } finally {
            //setIsSubmitting(false);
        }
    };

    return (
        <div className="space-y-6">
            <div className="text-center space-y-2">
                <h2 className="text-2xl font-semibold">
                    Additional Comments
                </h2>
                <p className="text-muted-foreground">
                    Your feedback helps us improve. Feel free to share more or skip.
                </p>
            </div>

            <div className="space-y-6">
                <div className="space-y-2">
                    <Label>Comments (Optional)</Label>
                    <div className="relative">
                        <MessageSquare className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
                        <Textarea
                            value={formData.comment}
                            onChange={(e) => handleChange('comment', e.target.value)}
                            rows={4}
                            className="pl-10"
                            placeholder="Share your thoughts..."
                        />
                    </div>
                </div>

                <div>
                    <Separator />
                    <p className="text-center text-sm text-muted-foreground my-4">
                        Contact Information (Optional)
                    </p>
                    <Separator />
                </div>

                <div className="space-y-4">
                    <div className="relative">
                        <UserIcon className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
                        <Input
                            placeholder="Name"
                            value={formData.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                            className="pl-10"
                        />
                    </div>

                    <div className="relative">
                        <Mail className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
                        <Input
                            type="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={(e) => handleChange('email', e.target.value)}
                            className="pl-10"
                        />
                    </div>

                    <div className="relative">
                        <Phone className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
                        <Input
                            type="tel"
                            placeholder="Phone"
                            value={formData.phone}
                            onChange={(e) => handleChange('phone', e.target.value)}
                            className="pl-10"
                        />
                    </div>
                </div>

                <div className="flex justify-center gap-4">
                    <Button
                        variant="outline"
                        onClick={() => onSubmit(true)}
                        disabled={isSubmitting}
                        className="w-32"
                    >
                        Skip
                    </Button>
                    <Button
                        onClick={() => onSubmit(false)}
                        disabled={isSubmitting}
                        className="w-32 gap-2"
                    >
                        Submit
                        <ArrowRight className="h-4 w-4" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default OptionalInfoStep;