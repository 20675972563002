import React, { useState, useEffect } from 'react';
import { Label } from "../ui/label";
import { Input } from "../ui/input"
import { Textarea } from "../ui/textarea"
import {
    RadioGroup,
    RadioGroupItem
} from "../ui/radio-group"
import { Button } from "../ui/button"
import { Card } from "../ui/card"
import api from '../../services/api';
import ProgressDots from './ProgressDots';
import { Alert, AlertDescription } from "../ui/alert";



const RatingInput = ({ value, onChange, labels }) => {
    const [hoverValue, setHoverValue] = useState(null);

    const ratings = [
        { emoji: "😡", value: 1 },
        { emoji: "🙁", value: 2 },
        { emoji: "😐", value: 3 },
        { emoji: "🙂", value: 4 },
        { emoji: "😄", value: 5 }
    ];

    return (
        <div className="space-y-2">
            <div className="flex justify-center gap-3">
                {ratings.map((rating) => (
                    <button
                        key={rating.value}
                        className={`
              transition-all duration-200 transform
              hover:scale-110 focus:outline-none focus:ring-2 focus:ring-primary rounded-lg
              p-3 text-2xl
              ${(hoverValue === rating.value || value === rating.value) ? 'scale-110 bg-primary/10' : ''}
            `}
                        onMouseEnter={() => setHoverValue(rating.value)}
                        onMouseLeave={() => setHoverValue(null)}
                        onClick={() => onChange(rating.value)}
                        type="button"
                    >
                        <span className="block">{rating.emoji}</span>
                    </button>
                ))}
            </div>
            <div className="flex justify-between text-sm text-muted-foreground px-2">
                <span>{labels.min}</span>
                <span>{labels.max}</span>
            </div>
        </div>
    );
};



const QuestionsStep = ({ categoryId, answers, onChange, onComplete }) => {

    const questions = [
        {
            id: 1,
            type: 'rating',
            question: 'How would you rate the overall worship experience?',
            labels: {
                min: 'Poor',
                max: 'Excellent'
            }
        },
        {
            id: 2,
            type: 'rating',
            question: 'How engaging was the sermon?',
            labels: {
                min: 'Not Engaging',
                max: 'Very Engaging'
            }
        },
        {
            id: 3,
            type: 'rating',
            question: 'How would you rate the music and worship team?',
            labels: {
                min: 'Poor',
                max: 'Excellent'
            }
        }
    ];

    //const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);

    const [localAnswers, setLocalAnswers] = useState(answers);
    const [error, setError] = useState('');

    const handleAnswerChange = (questionId, value) => {
        setLocalAnswers(prev => {
            const newAnswers = [...prev];
            const existingIndex = newAnswers.findIndex(a => a.questionId === questionId);

            if (existingIndex >= 0) {
                newAnswers[existingIndex] = { questionId, value };
            } else {
                newAnswers.push({ questionId, value });
            }

            return newAnswers;
        });
        setError('');
    };

    const handleSubmit = () => {
        // Validate all questions are answered
        const allAnswered = questions.every(q =>
            localAnswers.some(a => a.questionId === q.id && a.value)
        );

        if (!allAnswered) {
            setError('Please answer all questions before continuing');
            return;
        }

        onChange(localAnswers);
        onComplete();
    };

    const getAnswerValue = (questionId) => {
        const answer = localAnswers.find(a => a.questionId === questionId);
        return answer ? answer.value : null;
    };

    /*  useEffect(() => {
         const fetchQuestions = async () => {
             try {
                 const response = await api.getQuestions(categoryId);
                 setQuestions(response.data);
             } catch (error) {
                 console.error('Error fetching questions:', error);
             }
         };
         fetchQuestions();
     }, [categoryId]); */

    const handleAnswer = (answer) => {
        const newAnswers = [...answers];
        newAnswers[currentQuestion] = {
            question_id: questions[currentQuestion].id,
            answer,
        };
        onChange(newAnswers);

        // Automatic advancement
        if (currentQuestion < questions.length - 1) {
            setTimeout(() => setCurrentQuestion(prev => prev + 1), 300);
        } else {
            setTimeout(onComplete, 300);
        }
    };

    const renderQuestion = () => {
        const question = questions[currentQuestion];
        if (!question) return null;

        switch (question.question_type) {
            case 'single_choice':
                return (
                    <div className="space-y-4">
                        <Label>{question.question_text}</Label>
                        <RadioGroup
                            value={answers[currentQuestion]?.answer || ''}
                            onValueChange={handleAnswer}
                            className="space-y-2"
                        >
                            {question.options.map((option) => (
                                <Card
                                    key={option.id}
                                    className={`p-4 cursor-pointer hover:bg-accent transition-colors ${answers[currentQuestion]?.answer === option.option_text
                                        ? 'border-primary'
                                        : ''
                                        }`}
                                    onClick={() => handleAnswer(option.option_text)}
                                >
                                    <div className="flex items-center space-x-2">
                                        <RadioGroupItem value={option.option_text} />
                                        <Label>{option.option_text}</Label>
                                    </div>
                                </Card>
                            ))}
                        </RadioGroup>
                    </div>
                );

            case 'rating':
                return (
                    <div className="space-y-4">
                        <Label>{question.question_text}</Label>
                        <div className="flex justify-center gap-4">
                            {[1, 2, 3, 4, 5].map((value) => (
                                <Button
                                    key={value}
                                    variant={answers[currentQuestion]?.answer === value.toString() ? 'default' : 'outline'}
                                    className="h-12 w-12 rounded-full"
                                    onClick={() => handleAnswer(value.toString())}
                                >
                                    {value}
                                </Button>
                            ))}
                        </div>
                    </div>
                );

            default:
                return (
                    <div className="space-y-4">
                        <Label>{question.question_text}</Label>
                        <Textarea
                            value={answers[currentQuestion]?.answer || ''}
                            onChange={(e) => handleAnswer(e.target.value)}
                            rows={4}
                        />
                    </div>
                );
        }
    };
    return (
        <div className="space-y-8">
            {error && (
                <Alert variant="destructive">
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}

            {questions.map((question) => (
                <div key={question.id} className="space-y-3">
                    <Label className="text-base block">
                        {question.question}
                        <span className="text-red-500 ml-1">*</span>
                    </Label>

                    <RatingInput
                        value={getAnswerValue(question.id)}
                        onChange={(value) => handleAnswerChange(question.id, value)}
                        labels={question.labels}
                    />
                </div>
            ))}

            <Button
                className="w-full mt-8"
                onClick={handleSubmit}
            >
                Continue
            </Button>
        </div>
    );
};

export default QuestionsStep;

/*  return (
     <div className="space-y-6">
         {renderQuestion()}
         <ProgressDots
             steps={questions.length}
             activeStep={currentQuestion}
         />
     </div>
 );
}; 

export default QuestionsStep;
*/