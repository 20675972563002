import React from 'react';
import { Container } from '../components/ui/container';
import FeedbackForm from '../components/feedback/FeedbackForm';
import { useLocation } from 'react-router-dom';

const Feedback = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <FeedbackForm
                locationId={params.get('location')}
                eventId={params.get('event')}
            />
        </Container>
    );
};

export default Feedback;
