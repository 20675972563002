import React from 'react';
import { Button } from "../ui/button"
import { Card, CardContent } from "../ui/card"
import { Check } from "lucide-react"

const ThankYouScreen = ({ onReset }) => {
    return (
        <div className="container max-w-md mx-auto py-8">
            <Card className="text-center">
                <CardContent className="pt-12 pb-8 px-6">
                    <div className="relative mx-auto h-20 w-20 rounded-full bg-green-100 mb-8">
                        <div className="absolute inset-0 flex items-center justify-center animate-pulse">
                            <Check className="h-10 w-10 text-green-600" />
                        </div>
                    </div>

                    <h2 className="text-3xl font-semibold mb-4">
                        Thank You!
                    </h2>

                    <p className="text-muted-foreground mb-8">
                        Your feedback helps us improve our services.
                        We appreciate you taking the time to share your thoughts.
                    </p>

                    <Button
                        onClick={onReset}
                        className="min-w-[200px]"
                    >
                        Submit New Feedback
                    </Button>
                </CardContent>
            </Card>
        </div>
    );
};

export default ThankYouScreen;