import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
} from "../ui/dialog"
import { Input } from "../ui/input"
import { Button } from "../ui/button"
import { Alert, AlertDescription } from "../ui/alert"
import { useAuth } from '../../context/AuthContext';

const KioskExitDialog = ({ open, onClose, onExit }) => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [attempts, setAttempts] = useState(0);
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login({
                username: 'admin',
                password: password,
            });
            onExit();
        } catch (error) {
            setAttempts(prev => prev + 1);
            setError('Invalid password');
            setPassword('');

            if (attempts >= 2) {
                localStorage.setItem('kioskLockout', Date.now().toString());
                onClose();
            }
        }
    };

    return (
        <Dialog open={open} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Administrator Access</DialogTitle>
                    <DialogDescription>
                        Please enter administrator password to exit kiosk mode.
                    </DialogDescription>
                </DialogHeader>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <Input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={error ? "border-destructive" : ""}
                    />

                    {error && (
                        <p className="text-sm text-destructive">{error}</p>
                    )}

                    {attempts > 0 && (
                        <Alert variant="warning">
                            <AlertDescription>
                                {`${3 - attempts} attempts remaining`}
                            </AlertDescription>
                        </Alert>
                    )}

                    <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit">
                            Verify
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default KioskExitDialog;