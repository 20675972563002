import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';

// Auth Pages
import Login from './pages/auth/Login';
//import ForgotPassword from './pages/auth/ForgotPassword';
import { useAuth } from './hooks/useAuth';

// Public Pages
import Home from './pages/Home';
import Feedback from './pages/Feedback';

// Admin Pages
import Dashboard from './components/admin/Dashboard';
import UserManagement from './components/admin/UserManagement';
import EventsManagement from './components/admin/EventsManagement';
import KioskManagement from './components/admin/KioskManagement';
import LocationManagement from './components/admin/LocationManagement';
import './styles/globals.css';

import KioskMode from './components/kiosk/KioskMode';

import ServiceAnalyticsDashboard from './components/admin/ServiceAnalyticsDashboard';

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/auth/login" />;
  }

  return children;
};

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/feedback" element={<Feedback />} />

          {/* Auth Routes */}
          <Route path="/auth/login" element={<Login />} />
          {/*<Route path="/auth/forgot-password" element={<ForgotPassword />} />*/}
          <Route
            path="/demo-kiosk"
            element={
              <KioskMode>
                <Home />
              </KioskMode>
            }
          />

          <Route
            path="/analytics"
            element={
              <ServiceAnalyticsDashboard />
            }
          />

          {/* Admin Routes */}
          <Route
            path="/admin/*"
            element={
              <ProtectedRoute>
                <Routes>
                  <Route path="/" element={<Navigate to="dashboard" />} />
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="users" element={<UserManagement />} />
                  <Route path="events" element={<EventsManagement />} />
                  <Route path="kiosks" element={<KioskManagement />} />
                  <Route path="locations" element={<LocationManagement />} />
                </Routes>
              </ProtectedRoute>
            }
          />

          {/* Fallback Route */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;