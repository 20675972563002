import * as React from "react"
import { cn } from "../../lib/utils"

const containerVariants = {
    fluid: "w-full",
    sm: "max-w-screen-sm",
    md: "max-w-screen-md",
    lg: "max-w-screen-lg",
    xl: "max-w-screen-xl",
    "2xl": "max-w-screen-2xl"
}

const Container = React.forwardRef(({
    className,
    size = "lg",  // matches MUI's default
    ...props
}, ref) => {
    return (
        <div
            ref={ref}
            className={cn(
                "mx-auto w-full px-4",
                containerVariants[size],
                className
            )}
            {...props}
        />
    )
})
Container.displayName = "Container"

export { Container }