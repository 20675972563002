import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "../components/ui/button"
import { Container } from "../components/ui/container";

const Home = () => {
    const navigate = useNavigate();

    return (
        <div className="min-h-screen flex items-center justify-center">
            <Container className="max-w-3xl">
                <div className="flex flex-col items-center justify-center text-center space-y-8">
                    <h1 className="text-4xl font-bold tracking-tight lg:text-5xl">
                        Welcome to TG Feedback
                    </h1>

                    <p className="text-xl text-muted-foreground">
                        We collect and analyze your feedback to improve oour services
                    </p>

                    <div className="flex gap-4 mt-8">
                        <Button
                            size="lg"
                            onClick={() => navigate('/feedback')}
                        >
                            Give Feedback
                        </Button>
                        <Button
                            size="lg"
                            variant="outline"
                            onClick={() => navigate('/auth/login')}
                        >
                            Admin Login
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Home;