import React from 'react';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    PieChart,
    Pie,
    Cell,
} from 'recharts';
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card"
import mockEventData from './mockEventData';

const EventAnalytics = ({ eventData }) => {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    return (
        <div className="mt-6">
            <div className="grid gap-6 md:grid-cols-8">
                {/* Response Trend */}
                <Card className="md:col-span-5">
                    <CardContent className="p-6">
                        <CardTitle className="text-lg font-semibold mb-4">
                            Response Trend
                        </CardTitle>
                        <div className="h-[300px]">
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={eventData?.responseTrend || []}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="responses" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>

                {/* Satisfaction Distribution */}
                <Card className="md:col-span-3">
                    <CardContent className="p-6">
                        <CardTitle className="text-lg font-semibold mb-4">
                            Satisfaction Distribution
                        </CardTitle>
                        <div className="h-[300px]">
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={eventData?.satisfactionDistribution || []}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={80}
                                        fill="#8884d8"
                                    >
                                        {(eventData?.satisfactionDistribution || []).map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>

                {/* Summary Cards */}
                <div className="grid gap-4 sm:grid-cols-2 md:col-span-8">
                    <Card>
                        <CardContent className="p-6">
                            <div className="space-y-2">
                                <p className="text-sm text-muted-foreground">
                                    Total Responses
                                </p>
                                <p className="text-3xl font-semibold">
                                    {eventData?.totalResponses || 0}
                                </p>
                            </div>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent className="p-6">
                            <div className="space-y-2">
                                <p className="text-sm text-muted-foreground">
                                    Average Rating
                                </p>
                                <p className="text-3xl font-semibold">
                                    {eventData?.averageRating?.toFixed(1) || 0}
                                </p>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default EventAnalytics;