import React from 'react';
import { cn } from "../../lib/utils"

const ProgressDots = ({ steps, activeStep, className }) => {
    return (
        <div className={cn("flex justify-center gap-2", className)}>
            {[...Array(steps)].map((_, index) => (
                <div
                    key={index}
                    className={cn(
                        "h-2 w-2 rounded-full transition-all duration-300",
                        index === activeStep
                            ? "bg-primary w-4"
                            : "bg-muted"
                    )}
                />
            ))}
        </div>
    );
};

export default ProgressDots;