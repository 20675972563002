import React, { useState } from 'react';
import { format } from 'date-fns';
import { Calendar } from "../ui/calendar"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../ui/select"
import { Switch } from "../ui/switch"
import { Input } from "../ui/input"
import { Textarea } from "../ui/textarea"
import { Button } from "../ui/button"
import { Label } from "../ui/label"

const EventForm = ({ event, onSave, locations = [] }) => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        location: '',
        start_date: null,
        end_date: null,
        show_feedback_form: true,
        feedback_form_start: null,
        feedback_form_end: null,
        ...event,
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData);
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6 p-4">
            <div className="space-y-4">
                <div className="space-y-2">
                    <Label htmlFor="name">Event Name</Label>
                    <Input
                        id="name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        required
                    />
                </div>

                <div className="space-y-2">
                    <Label htmlFor="description">Description</Label>
                    <Textarea
                        id="description"
                        value={formData.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        rows={3}
                    />
                </div>

                <div className="space-y-2">
                    <Label htmlFor="location">Location</Label>
                    <Select
                        value={formData.location}
                        onValueChange={(value) => setFormData({ ...formData, location: value })}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="Select Location" />
                        </SelectTrigger>
                        <SelectContent>
                            {locations.map(location => (
                                <SelectItem key={location.id} value={location.id}>
                                    {location.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                <div className="grid gap-4 sm:grid-cols-2">
                    <div className="space-y-2">
                        <Label>Start Date</Label>
                        <Calendar
                            mode="single"
                            selected={formData.start_date ? new Date(formData.start_date) : undefined}
                            onSelect={(date) => setFormData({ ...formData, start_date: date?.toISOString() })}
                        />
                    </div>
                    <div className="space-y-2">
                        <Label>End Date</Label>
                        <Calendar
                            mode="single"
                            selected={formData.end_date ? new Date(formData.end_date) : undefined}
                            onSelect={(date) => setFormData({ ...formData, end_date: date?.toISOString() })}
                            disabled={(date) => {
                                if (!formData.start_date) return false;
                                return date < new Date(formData.start_date);
                            }}
                        />
                    </div>
                </div>

                <div className="flex items-center space-x-2">
                    <Switch
                        checked={formData.show_feedback_form}
                        onCheckedChange={(checked) => setFormData({
                            ...formData,
                            show_feedback_form: checked
                        })}
                    />
                    <Label>Show Feedback Form</Label>
                </div>

                {formData.show_feedback_form && (
                    <div className="space-y-4">
                        <h4 className="text-sm font-semibold">Feedback Form Availability</h4>
                        <div className="grid gap-4 sm:grid-cols-2">
                            <div className="space-y-2">
                                <Label>Start Collecting</Label>
                                <Calendar
                                    mode="single"
                                    selected={formData.feedback_form_start ? new Date(formData.feedback_form_start) : undefined}
                                    onSelect={(date) => setFormData({ ...formData, feedback_form_start: date?.toISOString() })}
                                />
                            </div>
                            <div className="space-y-2">
                                <Label>Stop Collecting</Label>
                                <Calendar
                                    mode="single"
                                    selected={formData.feedback_form_end ? new Date(formData.feedback_form_end) : undefined}
                                    onSelect={(date) => setFormData({ ...formData, feedback_form_end: date?.toISOString() })}
                                    disabled={(date) => {
                                        if (!formData.feedback_form_start) return false;
                                        return date < new Date(formData.feedback_form_start);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className="flex justify-end gap-4">
                <Button type="submit">
                    Save Event
                </Button>
            </div>
        </form>
    );
};

export default EventForm;