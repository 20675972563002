import React from 'react';
import { Card, CardContent } from "../ui/card"
import { cn } from "../../lib/utils"

const MetricCard = ({ title, value, icon, trend }) => {
    const isPositiveTrend = trend.startsWith('+');

    return (
        <Card>
            <CardContent className="p-6">
                <div className="flex items-center justify-between space-y-0">
                    <p className="text-sm font-medium text-muted-foreground">{title}</p>
                    <div className="text-muted-foreground">{icon}</div>
                </div>
                <div className="flex items-end justify-between mt-4">
                    <div className="text-2xl font-bold">{value}</div>
                    <div className={cn(
                        "text-sm font-medium",
                        isPositiveTrend ? "text-green-600" : "text-red-600"
                    )}>
                        {trend}
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

export default MetricCard;