import React, { useState, useEffect } from 'react';
import {
    ResponsiveContainer,
    LineChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Line,
    PieChart,
    Pie,
    Cell,
    BarChart,
    Bar
} from 'recharts';
import MetricCard from '../common/MetricCard';
import api from '../../services/api';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../ui/select"
import { Card, CardContent } from "../ui/card"
import {
    Activity,
    Users,
    SmilePlus,
    TrendingUp
} from "lucide-react"

const Dashboard = () => {
    const [filters, setFilters] = useState({
        location: 'all',
        event: 'all',
        timeframe: '30d'
    });

    const [locations, setLocations] = useState([]);
    const [events, setEvents] = useState([]);
    const [analytics, setAnalytics] = useState(null);

    const SATISFACTION_COLORS = {
        satisfied: '#4CAF50',
        neutral: '#FFC107',
        dissatisfied: '#F44336'
    };

    useEffect(() => {
        fetchLocations();
        fetchEvents();
    }, []);

    const fetchLocations = async () => {
        try {
            const response = await api.get('/locations');
            setLocations(response.data);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    const fetchEvents = async () => {
        try {
            const response = await api.get('/events');
            setEvents(response.data);
        } catch (error) {
            console.error('Error fetching events:', error);
        }
    };

    return (
        <div className="container py-8 mx-auto max-w-7xl">
            <div className="flex justify-between mb-8">
                <h1 className="text-3xl font-bold">Dashboard</h1>
                <div className="flex gap-4">
                    {/* Location Filter */}
                    <Select
                        value={filters.location}
                        onValueChange={(value) => setFilters(prev => ({ ...prev, location: value }))}
                    >
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Select Location" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="all">All Locations</SelectItem>
                            {locations.map(location => (
                                <SelectItem key={location.id} value={location.id}>
                                    {location.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>

                    {/* Event Filter */}
                    <Select
                        value={filters.event}
                        onValueChange={(value) => setFilters(prev => ({ ...prev, event: value }))}
                    >
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Select Event" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="all">All Events</SelectItem>
                            {events.map(event => (
                                <SelectItem key={event.id} value={event.id}>
                                    {event.name}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>

                    {/* View Type */}
                    <Select
                        value={filters.view}
                        onValueChange={(value) => setFilters(prev => ({ ...prev, view: value }))}
                    >
                        <SelectTrigger className="w-[180px]">
                            <SelectValue placeholder="Select View" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="overall">Overall</SelectItem>
                            <SelectItem value="events">Events</SelectItem>
                            <SelectItem value="hourly">Hourly</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <div className="grid gap-6 mb-8">
                {/* KPI Cards */}
                <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
                    <MetricCard
                        title="Total Responses"
                        value={analytics?.total_responses || 0}
                        icon={<Activity className="h-4 w-4" />}
                        trend={'+12.5%'}
                    />
                    <MetricCard
                        title="Today's Responses"
                        value={analytics?.today_responses || 0}
                        icon={<Users className="h-4 w-4" />}
                        trend={'+5.2%'}
                    />
                    <MetricCard
                        title="Satisfaction Rate"
                        value={`${(analytics?.satisfaction_rate || 0).toFixed(1)}%`}
                        icon={<SmilePlus className="h-4 w-4" />}
                        trend={'+3.8%'}
                    />
                    <MetricCard
                        title="Response Rate"
                        value={`${(analytics?.response_rate || 0).toFixed(1)}%`}
                        icon={<TrendingUp className="h-4 w-4" />}
                        trend={'+8.3%'}
                    />
                </div>

                {/* Charts */}
                <div className="grid gap-6 lg:grid-cols-8">
                    <Card className="lg:col-span-5">
                        <CardContent className="p-6">
                            <h3 className="text-lg font-semibold mb-4">Feedback Trend</h3>
                            <div className="h-[300px]">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={analytics?.trend_data || []}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="date"
                                            tickFormatter={(date) => new Date(date).toLocaleDateString()}
                                        />
                                        <YAxis yAxisId="left" />
                                        <YAxis yAxisId="right" orientation="right" />
                                        <Tooltip />
                                        <Line
                                            yAxisId="left"
                                            type="monotone"
                                            dataKey="count"
                                            stroke="#8884d8"
                                            name="Responses"
                                        />
                                        <Line
                                            yAxisId="right"
                                            type="monotone"
                                            dataKey="satisfaction_rate"
                                            stroke="#82ca9d"
                                            name="Satisfaction Rate"
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className="lg:col-span-3">
                        <CardContent className="p-6">
                            <h3 className="text-lg font-semibold mb-4">Satisfaction Distribution</h3>
                            <div className="h-[300px]">
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart>
                                        <Pie
                                            data={analytics?.satisfaction_distribution || []}
                                            dataKey="percentage"
                                            nameKey="satisfaction_level"
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={60}
                                            outerRadius={80}
                                            label
                                        >
                                            {analytics?.satisfaction_distribution?.map((entry, index) => (
                                                <Cell key={index} fill={SATISFACTION_COLORS[entry.satisfaction_level]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className="lg:col-span-8">
                        <CardContent className="p-6">
                            <h3 className="text-lg font-semibold mb-4">Hourly Distribution</h3>
                            <div className="h-[300px]">
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart data={analytics?.hourly_distribution || []}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="hour"
                                            tickFormatter={(hour) => `${hour}:00`}
                                        />
                                        <YAxis />
                                        <Tooltip />
                                        <Bar dataKey="count" fill="#8884d8" name="Responses" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;