import React from 'react';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ComposedChart,
    Cell
} from 'recharts';
import { Card, CardHeader, CardTitle, CardContent } from "../ui/card";
import { Button } from "../ui/button";
import { Download } from "lucide-react";

// Mock data structure following the sample report format
const mockData = {
    locationInfo: {
        name: "TG North Miami Campus [sample data]",
        dateRange: "12/01/24 - 12/01/24",
        index: 79.0,
        totalResponses: 19
    },
    satisfactionBreakdown: [
        { rating: "Very Happy", percentage: 67, responses: 13, color: "#4CAF50" },
        { rating: "Happy", percentage: 11, responses: 2, color: "#8BC34A" },
        { rating: "Unhappy", percentage: 11, responses: 2, color: "#FF9800" },
        { rating: "Very Unhappy", percentage: 11, responses: 2, color: "#F44336" }
    ],
    dailyDistribution: [
        {
            date: "9/26",
            "Very Happy": 61,
            "Happy": 13,
            "Unhappy": 13,
            "Very Unhappy": 13,
            index: 78
        },
        {
            date: "9/27",
            "Very Happy": 100,
            "Happy": 0,
            "Unhappy": 0,
            "Very Unhappy": 0,
            index: 95
        }
    ],
    hourlyDistribution: [
        { time: "9 AM", "Very Happy": 100, index: 100 },
        { time: "5 PM", "Very Happy": 100, index: 90 },
        { time: "6 PM", "Very Happy": 55, "Happy": 15, "Unhappy": 15, "Very Unhappy": 15, index: 70 },
        { time: "9 PM", "Very Happy": 100, index: 100 }
    ]
};

const ServiceAnalyticsDashboard = () => {
    const handleExportPDF = () => {
        // Implement PDF export functionality
        console.log("Exporting to PDF...");
    };

    const handleExportExcel = () => {
        // Implement Excel export functionality
        console.log("Exporting to Excel...");
    };

    return (
        <div className="p-6 space-y-6">
            {/* Header with Export Buttons */}
            <div className="flex justify-between items-center">
                <div>
                    <h1 className="text-2xl font-bold">{mockData.locationInfo.name}</h1>
                    <p className="text-sm text-muted-foreground">{mockData.locationInfo.dateRange}</p>
                </div>
                <div className="space-x-2">
                    <Button onClick={handleExportPDF} variant="outline">
                        <Download className="mr-2 h-4 w-4" />
                        Export PDF
                    </Button>
                    <Button onClick={handleExportExcel} variant="outline">
                        <Download className="mr-2 h-4 w-4" />
                        Export Excel
                    </Button>
                </div>
            </div>

            {/* Summary Stats */}
            <div className="grid grid-cols-2 gap-4">
                <Card>
                    <CardContent className="p-6">
                        <p className="text-sm text-muted-foreground">TG Members Satisfaction Index</p>
                        <p className="text-3xl font-bold">{mockData.locationInfo.index}</p>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent className="p-6">
                        <p className="text-sm text-muted-foreground">Total Responses</p>
                        <p className="text-3xl font-bold">{mockData.locationInfo.totalResponses}</p>
                    </CardContent>
                </Card>
            </div>

            {/* Satisfaction Distribution */}
            <Card>
                <CardHeader>
                    <CardTitle>Please rate our service today</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="h-[200px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                data={mockData.satisfactionBreakdown}
                                layout="vertical"
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis type="number" domain={[0, 100]} />
                                <YAxis dataKey="rating" type="category" />
                                <Tooltip />
                                <Bar dataKey="percentage" fill="#8884d8">
                                    {mockData.satisfactionBreakdown.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>

            {/* Daily Distribution */}
            <Card>
                <CardHeader>
                    <CardTitle>Daily Distribution</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="h-[300px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <ComposedChart data={mockData.dailyDistribution}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis yAxisId="left" orientation="left" />
                                <YAxis yAxisId="right" orientation="right" domain={[60, 100]} />
                                <Tooltip />
                                <Legend />
                                <Bar yAxisId="left" dataKey="Very Happy" stackId="a" fill="#4CAF50" />
                                <Bar yAxisId="left" dataKey="Happy" stackId="a" fill="#8BC34A" />
                                <Bar yAxisId="left" dataKey="Unhappy" stackId="a" fill="#FF9800" />
                                <Bar yAxisId="left" dataKey="Very Unhappy" stackId="a" fill="#F44336" />
                                <Line yAxisId="right" type="monotone" dataKey="index" stroke="#000" />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>

            {/* Hourly Distribution */}
            <Card>
                <CardHeader>
                    <CardTitle>Hourly Distribution</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="h-[300px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <ComposedChart data={mockData.hourlyDistribution}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="time" />
                                <YAxis yAxisId="left" orientation="left" />
                                <YAxis yAxisId="right" orientation="right" domain={[60, 100]} />
                                <Tooltip />
                                <Legend />
                                <Bar yAxisId="left" dataKey="Very Happy" stackId="a" fill="#4CAF50" />
                                <Bar yAxisId="left" dataKey="Happy" stackId="a" fill="#8BC34A" />
                                <Bar yAxisId="left" dataKey="Unhappy" stackId="a" fill="#FF9800" />
                                <Bar yAxisId="left" dataKey="Very Unhappy" stackId="a" fill="#F44336" />
                                <Line yAxisId="right" type="monotone" dataKey="index" stroke="#000" />
                            </ComposedChart>
                        </ResponsiveContainer>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default ServiceAnalyticsDashboard;