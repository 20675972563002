import React from 'react';
import { Button } from "../ui/button";

const EmotionFace = ({ color = "#22C55E", expression }) => {
    const expressions = {
        veryHappy: (
            <>
                <circle cx="12" cy="12" r="10" fill={color} />
                {/* Eyes with slight curve for more personality */}
                <path
                    d="M8 9.5c0 .4.3.7.7.7s.8-.3.8-.7-.4-.7-.8-.7-.7.3-.7.7z"
                    fill="white"
                />
                <path
                    d="M14.5 9.5c0 .4.3.7.7.7s.8-.3.8-.7-.4-.7-.8-.7-.7.3-.7.7z"
                    fill="white"
                />
                {/* Curved smile with rounded ends */}
                <path
                    d="M7.5 11.5c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
            </>
        ),
        happy: (
            <>
                <circle cx="12" cy="12" r="10" fill={color} />
                {/* Slightly smaller eyes */}
                <circle cx="8.5" cy="9.5" r="0.65" fill="white" />
                <circle cx="15.5" cy="9.5" r="0.65" fill="white" />
                {/* Gentler smile curve */}
                <path
                    d="M8 12.5c0 2 1.8 3.5 4 3.5s4-1.5 4-3.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
            </>
        ),
        neutral: (
            <>
                <circle cx="12" cy="12" r="10" fill={color} />
                {/* Oval eyes for more character */}
                <ellipse cx="8.5" cy="9.5" rx="0.7" ry="0.8" fill="white" />
                <ellipse cx="15.5" cy="9.5" rx="0.7" ry="0.8" fill="white" />
                {/* Slightly curved neutral line */}
                <path
                    d="M8.5 13.5h7"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
            </>
        ),
        sad: (
            <>
                <circle cx="12" cy="12" r="10" fill={color} />
                {/* Slightly angled eyes for worried look */}
                <path
                    d="M8.5 9.5c0 .4.3.7.7.7s.8-.3.8-.7-.4-.7-.8-.7-.7.3-.7.7z"
                    fill="white"
                    transform="rotate(-5 8.5 9.5)"
                />
                <path
                    d="M14.5 9.5c0 .4.3.7.7.7s.8-.3.8-.7-.4-.7-.8-.7-.7.3-.7.7z"
                    fill="white"
                    transform="rotate(5 15.5 9.5)"
                />
                {/* Gentle frown curve */}
                <path
                    d="M8 15c0-2 1.8-3.5 4-3.5s4 1.5 4 3.5"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
            </>
        ),
        custom: (props) => props.customSvgContent
    };

    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="drop-shadow-sm"
        >
            {expressions[expression]}
        </svg>
    );
};

const EmotionSelector = ({ value, onChange, customEmotions }) => {
    const defaultEmotions = [
        {
            label: 'Very Satisfied',
            value: 'very_satisfied',
            expression: 'veryHappy',
            color: '#22C55E'
        },
        {
            label: 'Satisfied',
            value: 'satisfied',
            expression: 'happy',
            color: '#93C5FD'
        },
        {
            label: 'Neutral',
            value: 'neutral',
            expression: 'neutral',
            color: '#FCD34D'
        },
        {
            label: 'Dissatisfied',
            value: 'dissatisfied',
            expression: 'sad',
            color: '#EF4444'
        }
    ];

    const emotions = customEmotions || defaultEmotions;

    return (
        <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            {emotions.map((emotion) => (
                <Button
                    key={emotion.value}
                    variant={value === emotion.value ? "default" : "outline"}
                    className="flex flex-col items-center p-6 h-auto gap-3 transition-all hover:scale-105 hover:shadow-md"
                    onClick={() => onChange(emotion.value)}
                >
                    <EmotionFace
                        expression={emotion.expression}
                        color={emotion.color}
                        customSvgContent={emotion.customSvgContent}
                    />
                    <span className="text-sm text-center font-medium">
                        {emotion.label}
                    </span>
                </Button>
            ))}
        </div>
    );
};

export default EmotionSelector;